import React from "react";
import { useSearchParams } from "react-router-dom";
const Errors = (props) => {
  const [params] = useSearchParams();
  const error = params.get("error");
  const description = params.get("error_description");
  return (
    <div className="bg-slate-100 m-auto mt-12  w-11/12 p-2 border-x-2 border-y-2 text-red-700">
      Error: {error}
      {", "} {description}
    </div>
  );
};

export default Errors;
