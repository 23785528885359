import { Configuration, V0alpha2Api } from "@ory/kratos-client";
import config from "../../env";
export default new V0alpha2Api(
  new Configuration({
    basePath: config.ORY_API_ENDPOINT,
    baseOptions: {
      withCredentials: true,
    },
  })
);
