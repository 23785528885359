import { useState } from "react";
import { loginRequest } from "../../initial-states";
import { getLoginRequest } from "../../../api/hydra/auth";
import { destroySession } from "../../../api/ory/auth";
const useLoginRequest = () => {
  const [state, setState] = useState(loginRequest);
  const set = (res) => {
    if (res?.loading === true || res?.loading === false) {
      setState((prevState) => {
        return {
          ...prevState,
          loading: res.loading,
        };
      });
    }
    if (res?.data) {
      setState({
        loading: false,
        data: res.data,
        error: undefined,
      });
    } else if (res?.error) {
      setState({
        loading: false,
        data: undefined,
        error: res.error,
      });
    }
    return state;
  };
  const get = (prop) => {
    return prop ? state[prop] : state;
  };

  const redirect = (obj) => {
    if (obj && obj?.redirect_to) {
      window.location.href = obj.redirect_to;
    }
  };
  const fetch = async (challenge, session = undefined) => {
    try {
      set({ loading: true });
      const response = await getLoginRequest(challenge, session);
      redirect(response);
      if (response) {
        set({ data: response });
        return response;
      }
    } catch (error) {
      console.log(error?.response?.data);
      redirect(error?.response?.data);
      set({ error: error?.response?.data });
    }
  };
  const logout = async () => {
    try {
      set({ loading: true });
      await destroySession();
      setState({ loading: false, data: undefined });
    } catch (error) {
      set({ error: error?.response?.data });
    }
  };
  return { set, get, state, fetch, logout };
};
export default useLoginRequest;
