import { removeEmail } from "../../utils/localstorage";
import ory from "../ory";
export const getLoginFlow = async (
  flowId = undefined,
  returnTo = undefined,
  refresh = true,
  aal = undefined
) => {
  try {
    const webAuthnScript = await ory.getWebAuthnJavaScript();
    // eslint-disable-next-line no-eval
    eval(webAuthnScript?.data);
    if (flowId) {
      const flow = await ory.getSelfServiceLoginFlow(flowId);
      return flow?.data;
    } else {
      const flow = await ory.initializeSelfServiceLoginFlowForBrowsers(
        refresh,
        aal,
        returnTo
      );
      return flow?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getFlowError = async (errorId) => {
  try {
    const response = await ory.getSelfServiceError(errorId);
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const extractAuthParams = async (data) => {
  try {
    const params = {
      method: data?.submit === "submit" ? "password" : "webauthn",
      flowId: "",
      csrf_token: "",
      identifier: "",
      password: "",
    };
    const flow = await getLoginFlow(undefined);
    if (flow && flow.id) {
      params.flowId = flow.id;
      const token = flow?.ui?.nodes.find(
        (node) => node?.attributes?.name === "csrf_token"
      );
      params.csrf_token = token?.attributes?.value;
    }
    return params;
  } catch (error) {
    throw error;
  }
};

export const authenticate = async (flowId, data) => {
  try {
    const response = await ory.submitSelfServiceLoginFlow(flowId, data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getSession = async () => {
  try {
    const session = await ory.toSession();
    return session?.data;
  } catch (error) {}
};

export const getAllActiveSessions = async () => {
  try {
    const sessions = await ory.listSessions();
    return sessions;
  } catch (error) {}
};

export const destroySession = async () => {
  try {
    const { data } = await ory.createSelfServiceLogoutFlowUrlForBrowsers();
    if (data?.logout_token) {
      await ory.submitSelfServiceLogoutFlow(data?.logout_token);
      removeEmail();
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
