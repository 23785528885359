const STORAGE_KEY = "loggedInUser";
export const saveEmail = (email) => {
  localStorage.setItem(STORAGE_KEY, email);
};

export const getEmail = () => {
  return localStorage.getItem(STORAGE_KEY);
};

export const removeEmail = () => {
  localStorage.removeItem(STORAGE_KEY);
};
