import { useState } from "react";
import { loginRequest } from "../../initial-states";
import {
  getConsentRequest,
  submitConsentRequest,
} from "../../../api/hydra/consent";
const useConsentRequest = () => {
  const [state, setState] = useState(loginRequest);
  const set = (res) => {
    if (res?.loading === true || res?.loading === false) {
      setState({
        loading: res.loading,
        data: undefined,
        error: undefined,
      });
    }
    if (res?.data) {
      setState({
        loading: false,
        data: res.data,
        error: undefined,
      });
    } else if (res?.error) {
      setState({
        loading: false,
        data: undefined,
        error: res.error,
      });
    }
    return state;
  };
  const get = (prop) => {
    return prop ? state[prop] : state;
  };

  const redirect = (obj) => {
    if (obj && obj?.redirect_to) {
      window.location.href = obj.redirect_to;
    }
  };

  const fetch = async (challenge) => {
    try {
      set({ loading: true });
      const response = await getConsentRequest(challenge);
      redirect(response);
      if (response) {
        set({ data: response });
        return response;
      }
    } catch (error) {
      console.log(error?.response?.data);
      redirect(error?.response?.data);
      set({ data: error?.response?.data });
    }
  };

  const handleSubmit = async (e, values, session) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      set({ loading: true });
      const checkedScopes = Object.keys(values.scopes).filter(
        (scope) => values.scopes[scope]
      );
      const payload = {
        ...values,
        grant_scope: checkedScopes,
      };
      const response = await submitConsentRequest(payload, session);
      if (response) {
        redirect(response);
      }
    } catch (error) {
      redirect(error?.response?.data);
    } finally {
      set({ loading: false });
    }
  };
  return { set, get, state, fetch, handleSubmit };
};
export default useConsentRequest;
