import { BeatLoader } from "react-spinners";
const Spinner = (props) => {
  const { loading = false, color = "#2E4C6D", size = 20 } = props;
  return (
    <div
      style={{
        bottom: "0",
        height: "100px",
        left: "10%",
        margin: "auto",
        position: "absolute",
        top: "0",
        right: "0",
        width: "100px",
      }}
    >
      <BeatLoader loading={loading} color={color} size={size} />
    </div>
  );
};
export default Spinner;
