import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useLogoutRequest from "../hooks/auth/useLogoutRequest";
const Logout = () => {
  const [params] = useSearchParams();
  const { logout } = useLogoutRequest();
  useEffect(() => {
    try {
      const logoutChallenge = params.get("logout_challenge") || undefined;
      if (logoutChallenge) {
        logout(logoutChallenge);
      }
    } catch (error) {}
  });

  return <div>logout</div>;
};

export default Logout;
